import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import Auth0ProviderWithNavigate from './auth/Auth0ProviderWrapper';
import { HelmetProvider } from 'react-helmet-async';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Auth0ProviderWithNavigate>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Auth0ProviderWithNavigate>
    </React.StrictMode >
  );
}
