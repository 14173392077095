export interface ConfigString {
  [key: string]: string;
}

// Function to get the environment from process.env
export function getEnvironment(): string {
  const env = process.env.REACT_APP_ENV_NAME || 'localhost';
  if (!['localhost', 'dev', 'prod'].includes(env)) {
    throw new Error(`Unknown environment: ${env}`);
  }
  return env;
}

const BASE_API_URLS: ConfigString = {
  localhost: 'https://api.wallscapes.ai/dev', // override with localhost and port if running backend locally
  dev: 'https://api.wallscapes.ai/dev',
  prod: 'https://api.wallscapes.ai/prod'
};

export const getBaseApiUrl = () => {
  const environment = getEnvironment();
  return BASE_API_URLS[environment];
};

const BASE_APP_URLS: ConfigString = {
  localhost: 'http://localhost:3000',
  dev: 'https://dev.wallscapes.ai',
  prod: 'https://wallscapes.ai',
};

/**
 * Get the Base URL for the app
 */
export const getBaseAppUrl = () => {
  const environment = getEnvironment();
  return BASE_APP_URLS[environment];
};