import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { getBaseApiUrl } from "./EnvironmentManager";

export interface Order {
  orderId?: string;
  customerOrderId?: string;
  userId: string;
  createdDate?: Date;
  updatedDate?: Date;
  externalId?: string;
  lineItems: LineItem[];
  shippingDetails?: ShippingDetails;
  amountSubtotal?: number;
  amountTax?: number;
  amountDiscount?: number;
  amountShipping?: number;
  amountTotal?: number;
  currency?: "USD";
  payment?: Payment;
  status: "pending" | "submitted" | "complete" | "error";
}

export interface LineItem {
  productId?: string;
  productUserId?: string;
  name?: string;
  description?: string;
  catalogItemId: string;
  catalogItemVariantId: string;
  vendorData?: any;
  quantity: number;
  price?: number;
  metadata?: any;
  fulfillmentStatus?: "fulfilled" | "unfulfilled";
}

export interface Address {
  country: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface ShippingDetails {
  name: string;
  email?: string;
  phone?: string;
  address: Address;
  method?: string;
}

export interface Payment {
  vendorName: string;
  paymentSessionId?: string;
  status?: string;
  paymentType?: string;
  amountTotal?: number;
  metadata?: any;
}

const getOrderApiUrl = () => {
  return `${getBaseApiUrl()}/order`;
};

const useOrderApi = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  const createOrder = async (cartItems: LineItem[], payment: Payment): Promise<Order> => {
    let order: Order = {
      userId: user?.sub || "0",
      lineItems: cartItems,
      payment,
      status: "pending",
    };
    return order;
  };

  const submitOrder = async (order: Order): Promise<Order> => {
    const endpointUrl = getOrderApiUrl();
    order.status = "submitted";
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(endpointUrl, order, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (
        (response?.status === 200 || response?.status === 201) &&
        response?.data?.data
      ) {
        return response.data.data as Order;
      } else {
        throw new Error(
          "No Order found in the response or status is not success."
        );
      }
    } catch (error: any) {
      console.log("Error submitting an order:", error?.message);
      throw new Error("Failed to create Order");
    }
  };

  const updateOrder = async (order: Order): Promise<Order> => {
    const endpointUrl = getOrderApiUrl();
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.put(endpointUrl, order, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response?.status === 200 && response?.data?.data) {
        return response.data.data as Order;
      } else {
        throw new Error("No Order found in response");
      }
    } catch (error: any) {
      console.log("Error updating the order:", error?.message);
      throw new Error("Failed to update the order");
    }
  };

  const getOrder = async (orderId: string, userId: string): Promise<Order> => {
    const params = new URLSearchParams({
      userId: userId,
    });

    const endpointUrl = `${getOrderApiUrl()}/${orderId}?${params.toString()}`;
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response?.status === 200 && response?.data?.data) {
        return response.data.data as Order;
      } else {
        throw new Error(
          "No order data found in the response or status is not success."
        );
      }
    } catch (error: any) {
      console.error("Error calling API:", error?.message);
      throw new Error("Failed to retrieve order data from the API");
    }
  };

  const getUserOrders = async (userId: string = "0", limit: number = 10, lastKey?: Record<string, any>): Promise<{ items: Array<Object>, lastKey: Record<string, any> | null }> => {
    const params = new URLSearchParams({
      userId: userId,
      limit: limit.toString(),
      lastKey: lastKey
        ? JSON.stringify(lastKey) // Encode the lastKey as a JSON string
        : "",
    });

    const endpointUrl = `${getOrderApiUrl()}?${params.toString()}`;
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      if (response?.status === 200 && response?.data?.status === 'success' && response?.data?.data) {
        return response.data.data;
      } else {
        throw new Error('No order data found in the response or status is not success.');
      }
    } catch (error) {
      console.error('Error calling API:', JSON.stringify(error));
      throw new Error('Failed to retrieve order data from the API');
    }
  };

  return { createOrder, submitOrder, getOrder, updateOrder, getUserOrders };
};

export default useOrderApi;
