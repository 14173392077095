import { Offcanvas, Stack } from "react-bootstrap"
import { useShoppingCart } from "../context/ShoppingCartContext"
import { formatCurrency } from "../utilities/formatCurrency"
import { CartItemComponent } from "./CartItem"
import CheckoutButton from "../views/payment/CheckoutButton"

type ShoppingCartProps = {
  isOpen: boolean
}

export function ShoppingCart({ isOpen }: ShoppingCartProps) {
  const { closeCart, cartItems } = useShoppingCart()

  const total = cartItems.reduce((total, cartItem) => {
    return total + (cartItem.price || 0) * cartItem.quantity
  }, 0)
  return (
    <Offcanvas show={isOpen} onHide={closeCart} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Cart</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Stack gap={3}>
          {cartItems.map(item => (
            <CartItemComponent key={item.productId} {...item} />
          ))}
          <div className="ms-auto fw-bold fs-5">
            Total{" "}
            {formatCurrency(total)}
          </div>
          <CheckoutButton cartItems={cartItems} />
        </Stack>
      </Offcanvas.Body>
    </Offcanvas>
  )
}