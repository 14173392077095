import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useShoppingCart } from '../context/ShoppingCartContext';

export const Navigation: React.FC = () => {
  const { isAuthenticated, logout } = useAuth0();
  const { openCart, cartQuantity } = useShoppingCart();

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="border-bottom">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src="/logo-wide.png" alt="Wallscapes.AI Logo" style={{ height: '60px', marginRight: '10px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/products">
              Products
            </Nav.Link>
            <Nav.Link as={Link} to="/products/custom">
              Design Your Own
            </Nav.Link>
            <Nav.Link as={Link} to="/account">
              Account
            </Nav.Link>
            <Nav.Link onClick={openCart} style={{ position: 'relative' }}>
              Cart
              {cartQuantity > 0 && (
                <div
                  className="rounded-circle bg-danger d-flex justify-content-center align-items-center"
                  style={{
                    color: 'white',
                    width: '1.5rem',
                    height: '1.5rem',
                    position: 'absolute',
                    top: '0',
                    right: '0rem',
                    transform: 'translate(50%, -50%)',
                  }}
                >
                  {cartQuantity}
                </div>
              )}
            </Nav.Link>
            <Nav.Link as={Link} to="/contactus">
              Contact Us
            </Nav.Link>
            {isAuthenticated && (
              <Nav.Link as={Link} onClick={() => logout()} to="#">
                Sign out
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar >
  );
};