import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import ProductList from "../products/component/ProductList";
import useUserApi, { User } from "../../managers/UserManager";
import OrderList from "../order/component/OrderList";

const Account: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { getOrCreateUser } = useUserApi(); // Access UserManager's getOrCreateUser function
  const [backendUser, setBackendUser] = useState<User | null>(null);
  const hasFetchedUser = useRef(false); // Ref to track fetch status

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (isAuthenticated && user && !hasFetchedUser.current) {
          const fetchedUser = await getOrCreateUser();
          setBackendUser(fetchedUser);
          hasFetchedUser.current = true; // Set the ref to true after fetching
        }
      } catch (error) {
        console.error("Error fetching or creating user in backend:", error);
        hasFetchedUser.current = true; // Avoid retrying infinitely on failure
      }
    };

    fetchUser();
  }, [isAuthenticated, user, getOrCreateUser]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <div>Please log in to access your account.</div>;
  }

  return (
    <Container>
      <div>
        <h1>Account</h1>
        <img src={user?.picture} alt={user?.name} />
        <h2>{user?.name}</h2>
        <p>Email: {user?.email}</p>
        <p>ID: {user?.sub}</p>
        {backendUser ? (
          <div>
            <h3>Additional Information</h3>
            <p>Created Date: {new Date(backendUser.createdDate || "").toLocaleString()}</p>
            <p>Updated Date: {new Date(backendUser.updatedDate || "").toLocaleString()}</p>
            <p>Role: {backendUser?.role}</p>
          </div>
        ) : (
          <p>Loading additional information...</p>
        )}
        <ProductList userId={user?.sub} />

        <OrderList userId={user?.sub} />
      </div>
    </Container>
  );
};

export default Account;
