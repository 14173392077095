import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Container, Row, Col, Card
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useOrderApi from '../../../managers/OrderManager';
import Loading, { LoadingStates } from '../../../components/Loading';

interface OrderListProps {
  userId?: string | null;
}

const OrderList: React.FC<OrderListProps> = ({ userId }) => {
  const [loadingState, setLoadingState] = useState<typeof LoadingStates[keyof typeof LoadingStates]>(LoadingStates.LOADING);
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const { getUserOrders } = useOrderApi();
  const [orders, setOrders] = useState<any[]>([]);
  const lastKeyRef = useRef<Record<string, any> | null>(null);
  const isFetching = useRef(false);
  const hasMoreOrders = useRef(true);
  const observer = useRef<IntersectionObserver | null>(null);

  const fetchOrders = useCallback(async () => {
    if (isFetching.current || !hasMoreOrders.current || !userId) return;

    isFetching.current = true;
    setLoadingState(LoadingStates.LOADING);
    try {
      const data = await getUserOrders(userId, 10, lastKeyRef.current || undefined);

      if (data.items.length === 0) {
        hasMoreOrders.current = false;
      } else {
        setOrders(prev => [...prev, ...data.items]);
        lastKeyRef.current = data.lastKey || null;
        if (!data.lastKey) hasMoreOrders.current = false;
      }

      setLoadingState(LoadingStates.SUCCESS);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setLoadingMessage("Error fetching orders: " + error);
      setLoadingState(LoadingStates.ERROR);
    } finally {
      isFetching.current = false;
    }
  }, [userId]);

  useEffect(() => {
    if (orders.length === 0) {
      fetchOrders();
    }
  }, [fetchOrders]);

  const lastOrderElementRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !isFetching.current && hasMoreOrders.current) {
          fetchOrders();
        }
      },
      { root: null, rootMargin: "200px", threshold: 0 }
    );

    if (node) observer.current.observe(node);
  }, [fetchOrders]);

  if ((loadingState === LoadingStates.LOADING || loadingState === LoadingStates.ERROR) && orders.length === 0) {
    return <Loading state={loadingState} message={loadingMessage} />;
  }

  if (!orders || orders.length === 0) {
    return <div>No orders found.</div>;
  }

  return (
    <Container>
      <Row>
        {orders.map((order, index) => (
          <Col
            key={order.customerOrderId || index}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            ref={orders.length === index + 1 ? lastOrderElementRef : null}
          >
            <Link to={`/order/${order.orderId}`} className="text-decoration-none text-dark">
              <Card className="mb-3 ws-img-clickable">
                <Card.Body>
                  <Card.Title>Order #{order.customerOrderId}</Card.Title>
                  <Card.Text>Status: {order.status}</Card.Text>
                  <Card.Text>Created: {new Date(order.createdDate).toLocaleDateString()}</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default OrderList;
