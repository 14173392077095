import React from 'react';
import { Container } from 'react-bootstrap';

const License: React.FC = () => {
  return (
    <Container className="py-5">
      <h1 className="text-center mb-4">AI Image License Policy</h1>
      <p className="text-muted text-center">Effective Date: 2025-03-05</p>

      <p>
        This Image License Policy outlines how AI-generated images
        created on our platform can be used, shared, and distributed. By using Wallscapes.AI, you agree to these terms.
      </p>

      <h3 className="mt-4">1. Ownership of AI-Generated Images</h3>
      <p>
        All AI-generated images created through <strong>Wallscapes.AI</strong> are owned by Wallscapes.AI and are
        licensed, not sold, to users. Users do not gain copyright ownership of AI-generated images but are granted
        a <strong>limited-use license</strong> as outlined below.
      </p>

      <h3 className="mt-4">2. License Types & Usage Rights</h3>
      <p>Wallscapes.AI offers the following licenses based on how the images will be used:</p>

      <h4 className="text-primary mt-3">Personal Use License</h4>
      <p><strong>Permitted Uses:</strong></p>
      <ul>
        <li>Printing images for <strong>personal use</strong> (e.g., wall art, posters, phone backgrounds).</li>
        <li>Sharing images on <strong>social media</strong> with credit to Wallscapes.AI.</li>
        <li>Using images in <strong>non-commercial projects</strong> (e.g., blogs, presentations).</li>
      </ul>

      <p><strong>Prohibited Uses:</strong></p>
      <ul>
        <li>Selling or distributing the images as <strong>standalone digital or physical products</strong>.</li>
        <li>Using the images for <strong>advertising, marketing, or branding</strong>.</li>
        <li>Uploading to <strong>stock photo platforms</strong> (e.g., Shutterstock, Getty Images).</li>
        <li>Claiming <strong>ownership or copyright</strong> over the image.</li>
      </ul>

      <h3 className="mt-4">3. Prohibited Uses (All Licenses)</h3>

      <p><strong>Illegal or Harmful Content:</strong></p>
      <ul>
        <li>Using images for <strong>hate speech, violence, or defamation</strong>.</li>
        <li>Creating <strong>deceptive or false representations</strong> of real people.</li>
        <li>Promoting <strong>misleading political content</strong>.</li>
      </ul>

      <p><strong>Trademark or Brand Infringement:</strong></p>
      <ul>
        <li>Using AI-generated images in a way that suggests <strong>endorsement by a brand</strong>.</li>
        <li>Modifying and using AI images to resemble <strong>trademarked characters or logos</strong>.</li>
      </ul>

      <p><strong>Misuse of AI-Generated Images:</strong></p>
      <ul>
        <li>Reselling images as <strong>NFTs or in blockchain-related applications</strong>.</li>
        <li>Generating AI images based on <strong>copyrighted prompts</strong> to recreate protected works.</li>
      </ul>

      <p className="text-danger"><strong>Wallscapes.AI reserves the right to revoke licenses if any prohibited use is detected.</strong></p>

      <h3 className="mt-4">4. Indemnification & Limitation of Liability</h3>
      <p>
        <strong>Wallscapes.AI is not responsible for legal claims</strong> arising from misuse of AI-generated images.
        Users agree to <strong>indemnify Wallscapes.AI</strong> against any legal disputes arising from the use of licensed images.
      </p>
      <p><strong>Note:</strong> AI-generated images are created uniquely, but similar outputs may appear for other users.</p>

      {/* Section 5 - Commercial License */}
      <h3 className="mt-4">5. How to Get a Commercial License</h3>
      <p>
        If you want to use AI-generated images for commercial purposes (e.g., reselling products, marketing materials),
        you must obtain a **Commercial License**.
      </p>
      <p>
        <strong>For commercial licensing inquiries, contact:</strong>
        <a href="mailto:wallscapesai@gmail.com"> wallscapesai@gmail.com</a>
      </p>

      {/* Section 6 - Policy Updates */}
      <h3 className="mt-4">6. Changes to This Policy</h3>
      <p>
        <strong>Wallscapes.AI reserves the right</strong> to update this policy at any time. Users will be notified of changes via **email** or **website announcement**.
      </p>

      <p><strong>For any questions, contact:</strong> <a href="mailto:wallscapesai@gmail.com">wallscapesai@gmail.com</a></p>

    </Container>
  );
};

export default License;
