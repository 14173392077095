import axios from "axios";
import { getBaseApiUrl, getEnvironment } from "./EnvironmentManager";

interface ApiKeys {
  [key: string]: string;
}

export class Contact {
  name?: string;
  email?: string;
  mobile?: string;
  orderNumber?: string;
  message?: string;
}

const getSitemapApiUrl = () => {
  return `${getBaseApiUrl()}/sitemap.xml`;
};

const getSiteContactApiUrl = () => {
  return `${getBaseApiUrl()}/site/contact`;
};

const captchaKey: ApiKeys = {
  localhost: "10000000-ffff-ffff-ffff-000000000001",
  dev: "10000000-ffff-ffff-ffff-000000000001",
  prod: "fabca89c-131a-4b2c-9628-96288c2220b4",
};

const siteName = "Wallscapes";

// Custom hook to fetch and stream sitemap XML and perform site actions
const useSiteApi = () => {

  const getCaptchaKey = (): string => {
    const environment = getEnvironment();
    return captchaKey[environment] || captchaKey.dev;
  };

  // Function to fetch sitemap as a stream
  const fetchSitemap = async (): Promise<ReadableStream | string> => {
    const endpointUrl = getSitemapApiUrl();

    try {
      const response = await axios.get(endpointUrl, {
        headers: {
          "Content-Type": "application/xml",
        },
        responseType: "stream", // Enable streaming response
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching sitemap:", error);
      throw new Error("Failed to retrieve sitemap");
    }
  };

  // Function to post a "contactUs" action to the site service
  const postContactUs = async (contact: Contact): Promise<any> => {
    const endpointUrl = getSiteContactApiUrl()

    try {
      const response = await axios.post(endpointUrl, contact);
      return response.data;
    } catch (error) {
      console.error("Error posting contact us request:", error);
      throw new Error("Failed to send contact us request");
    }
  };

  const getSiteName = (): string => {
    return siteName;
  };

  return { fetchSitemap, getCaptchaKey, getSiteName, postContactUs };
};

export default useSiteApi;
