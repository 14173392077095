import React from 'react';
import { Container } from 'react-bootstrap';

const Returns: React.FC = () => {

  return (
    <Container>
      <div>
        <h1>Return and Refund Policy for Wallscapes.AI</h1>
        <p className="text-muted text-center">Effective Date: 2025-03-05</p>

        <p>
          Wallscapes.AI is committed to providing high-quality, customized products that meet your expectations.
          However, as all products are made-to-order and involve personalized designs, the following return and refund policy applies:
        </p>

        <h2>1. No Returns for Customized Products</h2>
        <ul>
          <li>
            Due to the custom nature of our products, we cannot accept returns or exchanges for items once they have been produced,
            except in cases of damage, defect, or production errors as detailed below.
          </li>
        </ul>

        <h2>2. Damaged, Defective, or Incorrect Products</h2>
        <ul>
          <li>
            If you receive a product that is damaged, defective, or does not match your order, please contact our customer support
            team at wallscapesai@gmail.com within 7 days of delivery. To process your claim, you will need to provide:
            <ul>
              <li>Your order number</li>
              <li>A description of the issue</li>
              <li>Clear photographs of the product and the packaging</li>
            </ul>
          </li>
          <li>Upon review, if the issue is deemed valid:</li>
          <ul>
            <li><strong>Replacement:</strong> We will reproduce and ship the corrected product at no additional cost to you.</li>
            <li><strong>Refund:</strong> In cases where a replacement is not feasible, we may issue a full or partial refund at our discretion.</li>
          </ul>
        </ul>

        <h2>3. Order Cancellation</h2>
        <ul>
          <li>
            Orders can only be canceled within 24 hours of placement, provided production has not started. After this time,
            cancellations are not possible as the order will be in production with our print-on-demand vendors.
          </li>
          <li>
            To cancel an order, please contact us immediately at wallscapesai@gmail.com. If production has not commenced,
            we will process your cancellation and issue a full refund.
          </li>
        </ul>

        <h2>4. Limitations of Liability</h2>
        <ul>
          <li>Wallscapes.AI is not responsible for:</li>
          <ul>
            <li>Minor variations in color or design due to differences in display settings or print technology.</li>
            <li>Errors in designs uploaded by the customer. Please review your design carefully before placing an order.</li>
          </ul>
        </ul>

        <h2>5. Shipping Issues</h2>
        <ul>
          <li>
            <strong>Lost or Undelivered Orders:</strong> If your order is lost or undelivered, we will work with our shipping partners to resolve the issue.
            If the issue cannot be resolved, we may issue a replacement or refund at our discretion.
          </li>
          <li>
            <strong>Customer Address Errors:</strong> If a package is undeliverable due to an incorrect or incomplete address provided by the customer,
            the customer is responsible for additional shipping costs to resend the package.
          </li>
        </ul>

        <h2>6. Contact Us</h2>
        <p>If you have any questions or concerns about your order, please contact us at:</p>
        <ul>
          <li><strong>Email:</strong> wallscapesai@gmail.com</li>
        </ul>
      </div>
    </Container>
  );
};

export default Returns;