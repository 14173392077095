import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from 'reactstrap';
import useProductApi, { Product } from '../../managers/ProductManager';
import ProductDetail from "./component/ProductDetail";
import { Helmet } from "react-helmet-async";

const ProductPage: React.FC = () => {
  const { productId } = useParams<{ catalogItemId: string; category: string; productId: string }>();
  const { getProduct } = useProductApi();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchProduct() {
      try {
        if (productId) {
          const fetchedProduct = await getProduct(productId);
          setProduct(fetchedProduct);
        } else {
          throw new Error("Missing product ID");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchProduct();
  }, [productId]);

  if (loading) return <Spinner animation="border" />;
  if (!product) return <p>Product not found</p>;

  return (
    <>
      <Helmet>
        <title>{`${product.name} | Wallscapes.AI`}</title>
        <meta name="description" content={product.description} />
        <meta property="og:title" content={product.name} />
        <meta property="og:description" content={product.description} />
        {Array.isArray(product.previewImageUrls) && product.previewImageUrls[0] && (
          <meta property="og:image" content={product.previewImageUrls[0]} />
        )}
        <meta property="og:url" content={`https://wallscapes.ai/products/${product.productId}`} />
      </Helmet>
      <ProductDetail product={product} />
    </>
  );
};

export default ProductPage;
