import React from 'react';
import { Container } from 'react-bootstrap';

const Shipping: React.FC = () => {

  return (
    <Container>
      <div>
        <h1>Shipping Policy for Wallscapes.AI</h1>
        <p className="text-muted text-center">Effective Date: 2025-03-05</p>

        <p>
          Wallscapes.AI partners with print-on-demand vendors to produce and ship custom-designed products directly to you.
          Please review our shipping policy to understand how we handle and deliver your orders.
        </p>

        <h2>1. Order Processing Times</h2>
        <ul>
          <li><strong>Production Time:</strong> Each product is custom-made upon order. Production times typically range from 1 to 5 business days, depending on the product and the print-on-demand vendor. High demand or complex designs may extend production times.</li>
          <li><strong>Shipping Time:</strong> Once your order has been produced, it will be shipped based on the shipping method selected at checkout. Shipping times vary depending on the destination and carrier.</li>
          <li>You will receive a shipping confirmation email with tracking information once your order has been dispatched.</li>
        </ul>

        <h2>2. Shipping Methods and Costs</h2>
        <ul>
          <li>Shipping options and rates are displayed at checkout based on your location and the product ordered.</li>
          <li>Expedited shipping may be available for an additional fee; however, production times remain the same.</li>
        </ul>

        <h2>3. International Shipping</h2>
        <ul>
          <li>Wallscapes.AI ships internationally to most locations. Please note:</li>
          <ul>
            <li>Delivery times for international orders may vary significantly based on customs clearance and carrier availability.</li>
            <li>Import duties, taxes, and additional fees may be assessed by your country's customs office. These are the customer's responsibility and are not included in the order total.</li>
          </ul>
        </ul>

        <h2>4. Delayed or Lost Shipments</h2>
        <ul>
          <li><strong>Delays:</strong> Wallscapes.AI is not responsible for delays caused by carriers, weather, or customs clearance.</li>
          <li><strong>Lost Packages:</strong> If your tracking information indicates a lost package, contact us at wallscapesai@gmail.com within 14 days of the expected delivery date. We will work with the carrier to resolve the issue and may issue a replacement or refund if the package cannot be located.</li>
        </ul>

        <h2>5. Address Errors</h2>
        <ul>
          <li>It is the customer's responsibility to provide a complete and accurate shipping address at checkout.</li>
          <li>Orders shipped to incorrect addresses provided by the customer are not eligible for refunds or free replacements.</li>
          <li>If an order is returned due to an incorrect address, the customer may be responsible for additional shipping fees to resend the package.</li>
        </ul>

        <h2>6. Multiple Shipments</h2>
        <ul>
          <li>For orders with multiple items, products may ship separately depending on the print-on-demand vendor and production timelines.</li>
          <li>You will receive tracking information for each shipment.</li>
        </ul>

        <h2>7. Contact Us</h2>
        <p>If you have questions about shipping or need assistance with your order, please contact us at:</p>
        <ul>
          <li><strong>Email:</strong> wallscapesai@gmail.com</li>
        </ul>
      </div>
    </Container>
  );
};

export default Shipping;