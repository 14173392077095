import React from 'react';
import { Container } from 'react-bootstrap';

const Terms: React.FC = () => {

  return (
    <Container>
      <div>
        <h1>Terms of Service for Wallscapes.AI</h1>
        <p className="text-muted text-center">Effective Date: 2025-03-05</p>

        <h2>Overview</h2>
        <p>
          Wallscapes.AI, operated by GSD Solutions LLC, provides a platform where users can generate AI-designed images and purchase customized print-on-demand products (e.g., flags, posters). By using Wallscapes.AI, you agree to these Terms of Service.
        </p>

        <h2>1. Use of the Service</h2>
        <ul>
          <li>You must be at least 18 years old or the age of majority in your jurisdiction.</li>
          <li>Your use of our Service must comply with all applicable laws and regulations.</li>
          <li>You are prohibited from uploading content that infringes on intellectual property rights, is illegal, or violates these Terms.</li>
        </ul>

        <h2>2. Product Fulfillment</h2>
        <ul>
          <li>Products are manufactured and shipped by third-party print-on-demand vendors.</li>
          <li>Wallscapes.AI does not guarantee color or design accuracy due to variations in production technology.</li>
          <li>All sales are subject to the return policy outlined separately.</li>
        </ul>

        <h2>3. Pricing and Payments</h2>
        <ul>
          <li>Prices are subject to change without notice.</li>
          <li>Payment must be made at the time of order placement.</li>
          <li>Taxes and shipping fees will apply based on your location and the vendor's terms.</li>
        </ul>

        <h2>4. User Content</h2>
        <ul>
          <li>You retain ownership of content you upload but grant Wallscapes.AI a license to use it for production and marketing purposes.</li>
          <li>You represent that you have the right to use and upload the content provided.</li>
        </ul>

        <h2>5. Intellectual Property</h2>
        <ul>
          <li>Wallscapes.AI respects copyright laws. If you believe your intellectual property rights have been violated, contact us at wallscapesai@gmail.com.</li>
          <li>AI-generated designs may not infringe on third-party copyrights or trademarks.</li>
        </ul>

        <h2>6. Disclaimers and Limitations of Liability</h2>
        <ul>
          <li>Wallscapes.AI is provided "as is" without warranties of any kind.</li>
          <li>GSD Solutions LLC is not responsible for delays, errors, or damages caused by third-party vendors.</li>
        </ul>

        <h2>7. Modifications</h2>
        <p>
          These Terms may be updated periodically. Continued use of the service constitutes acceptance of the updated Terms.
        </p>
      </div>
    </Container>
  );
};

export default Terms;