import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { getBaseApiUrl } from './EnvironmentManager';

interface ContentRequestParams {
  userId: string;
  action: string;
  prompt?: string;
  vendor?: string;
}

const getContentApiUrl = () => {
  return `${getBaseApiUrl()}/content`;
};

export const IMAGE_TAG_KEYS: string[] = [
  "sports-fitness",
  "cars-transportation",
  "music-entertainment",
  "college-education",
  "science-technology",
  "chimpanzees-primates",
  "gambling-casino",
  "military-patriotic",
  "fantasy-surreal",
  "space-scifi",
  "health-lifestyle",
  "city-urban",
  "food-drink",
  "modern-minimalistic",
  "industrial",
  "architecture-design",
  "artistic",
  "cartoon",
  "vintage-retro",
  "nature-outdoors",
  "animals-insects",
  "people",
  "holiday-seasonal"
];

const useContentApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  /**
   * Generate a name and tags based on a prompt.
   * @param {ContentRequestParams} params - Parameters for generating a name and tags.
   * @returns {Promise<{ imageName: string, imageTags: string[] }>} - Generated name and valid tags.
   */
  const generateImageContent = async (
    params: ContentRequestParams
  ): Promise<{ imageName: string; imageTags: string[] }> => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(getContentApiUrl(), params, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response?.status === 200) {
        const { imageName, imageTags } = response.data?.content || {};

        if (!imageName) {
          throw new Error('Invalid response format: "imageName" is missing.');
        }

        // Validate that tags are from the predefined list
        const validTags = (Array.isArray(imageTags) ? imageTags : []).filter((tag) =>
          IMAGE_TAG_KEYS.includes(tag)
        );

        return { imageName, imageTags: validTags };
      } else {
        throw new Error(`Failed to generate content. Response status: ${response.status}`);
      }
    } catch (error: any) {
      console.error('Error generating image content:', error.response?.data || error.message);
      throw new Error('Failed to generate image content.');
    }
  };

  /**
   * Retrieve prompt ideas.
   * @returns {Promise<string[]>} - List of prompt ideas.
   */
  const generatePromptIdeas = async (): Promise<string[]> => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(getContentApiUrl(), {
        params: {
          operation: 'generatePromptIdeas',
          userId: 0,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (response?.status === 200) {
        const promptIdeas = response.data?.data?.content?.promptIdeas;
        if (!Array.isArray(promptIdeas)) {
          throw new Error('Invalid response format: "promptIdeas" must be an array.');
        }
        return promptIdeas;
      } else {
        console.error(`Error retrieving prompt ideas (status=${response?.status}):`);
        throw new Error('Failed to retrieve prompt ideas. Response status not successful.');
      }
    } catch (error: any) {
      console.error('Error retrieving prompt ideas:', error.response?.data || error.message);
      throw new Error('Failed to retrieve prompt ideas.');
    }
  };
  return { generateImageContent, generatePromptIdeas };
};

export default useContentApi;
