import React from "react";
import {
  Button, Container, Row, Col, Spinner
} from 'reactstrap';
import {
  FacebookShareButton, TwitterShareButton, PinterestShareButton
} from "react-share";
import {
  FaFacebook, FaXTwitter, FaPinterest, FaEnvelope, FaRegCopy, FaInstagram
} from "react-icons/fa6"; // FontAwesome icons
import { toast, ToastContainer } from "react-toastify"; // Toast notifications
import "react-toastify/dist/ReactToastify.css"; // Toast styles
import { formatCurrency } from '../../../utilities/formatCurrency';
import useProductApi, { Product } from '../../../managers/ProductManager';
import { useShoppingCart } from "../../../context/ShoppingCartContext";
import { getBaseAppUrl } from "../../../managers/EnvironmentManager";
import useSiteApi from "../../../managers/SiteManager";

interface ProductDetailProps {
  product: Product;
}

const ProductDetail: React.FC<ProductDetailProps> = ({ product }) => {
  const { getProductUrl } = useProductApi();
  const { getSiteName } = useSiteApi();

  const {
    getItemQuantity,
    addToCart,
    removeFromCart,
    openCart,
  } = useShoppingCart();

  if (!product) return <p>Product not found</p>;

  const handleAddToCart = async (product: any) => {
    addToCart({
      productId: product?.productId,
      productUserId: product?.userId,
      name: product.name,
      description: product?.catalogItemVariantName,
      catalogItemId: product?.catalogItemId,
      catalogItemVariantId: product?.catalogItemVariantId,
      price: product?.price,
      quantity: 1,
      metadata: { ...product?.metadata, previewImageUrls: product?.previewImageUrls },
    });
    openCart();
  }

  const siteName = getSiteName();
  const productUrl = getProductUrl(product) || ''; // TODO handle invalid URL
  const productImgUrl = product?.previewImageUrls?.[0] || `${getBaseAppUrl()}/default-placeholder.png`;
  const quantity = product.productId ? getItemQuantity(product?.productId) : 0;
  // Construct mailto link for email sharing
  const mailtoLink = `mailto:?subject=${encodeURIComponent(
    `Check out this product from ${siteName}`
  )}&body=${encodeURIComponent(
    `Hey,\n\nI found this amazing product on ${siteName} and thought you'd like it!\n\nCheck it out: ${productUrl}\n\nBest,\n[Your Name]`
  )}`;
  const SHARE_ICON_SIZE = 26;

  // Function to Copy Link to Clipboard with Toast
  const copyToClipboard = async (message: string) => {
    try {
      await navigator.clipboard.writeText(productUrl);
      toast.success(message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error("Failed to copy link:", error);
      toast.error("Failed to copy link.");
    }
  };

  return (
    <Container>
      <ToastContainer />
      <Row className="justify-content-center">
        <Col xs={12} md={7} className="text-center">
          <img
            src={productImgUrl}
            alt="Generated Product"
            className="img-fluid"
            style={{ maxWidth: '100%', objectFit: 'contain', borderRadius: "8px" }}
          />
        </Col>
        <Col xs={12} md={5}>
          <h3>{product?.name}</h3>
          <p className="fw-bold">{product?.catalogItemVariantName}</p>
          <span className="fw-bold">{formatCurrency(product?.price)}</span><br />
          {quantity === 0 ? (
            <Button color="primary" className="w-100" onClick={() => handleAddToCart(product)}>
              + Add To Cart
            </Button>
          ) : (
            <Button
              onClick={() => removeFromCart(product?.productId || "")}
              className="w-100"
              variant="secondary"
            >
              - Remove From Cart
            </Button>
          )}

          {/* Instagram Share Button (Copies Link) */}
          <Button
            title="Copy for Instagram Story"
            onClick={() =>
              copyToClipboard("Link copied to clipboard! Open Instagram, go to your Story, and paste the link.")
            }
            style={{ background: "transparent", border: "none", cursor: "pointer" }}
          >
            <FaInstagram size={SHARE_ICON_SIZE} color="#E4405F" />
          </Button>

          <FacebookShareButton
            url={productUrl}
            title={product?.name}
            hashtag="#Wallscapes"
          >
            <Button title="Share on Facebook" style={{ background: "transparent", border: "none" }}>
              <FaFacebook size={SHARE_ICON_SIZE} color="#1877F2" />
            </Button>
          </FacebookShareButton>

          <TwitterShareButton
            url={productUrl}
            title={product?.name}
            via="WallscapesAI"
            hashtags={["Wallscapes", "Decor"]}
            related={["Wallscapes"]}
          >
            <Button title="Share on X (Twitter)" style={{ background: "transparent", border: "none" }}>
              <FaXTwitter size={SHARE_ICON_SIZE} color="black" />
            </Button>
          </TwitterShareButton>

          <PinterestShareButton
            url={productUrl}
            media={productImgUrl}
            description={`${product?.name} - ${product?.description}`}
          >
            <Button title="Share on Pinterest" style={{ background: "transparent", border: "none" }}>
              <FaPinterest size={SHARE_ICON_SIZE} color="#E60023" />
            </Button>
          </PinterestShareButton>

          {/* Custom Email Share Button using mailto */}
          <a href={mailtoLink} title="Share via Email">
            <Button style={{ background: "transparent", border: "none" }}>
              <FaEnvelope size={SHARE_ICON_SIZE} color="gray" />
            </Button>
          </a>

          {/* Clipboard Copy Button with Toast */}
          <Button
            title="Copy Link"
            onClick={() => copyToClipboard("Link copied to clipboard!")}
            style={{ background: "transparent", border: "none", cursor: "pointer" }}
          >
            <FaRegCopy size={SHARE_ICON_SIZE} color="gray" />
          </Button>
          <p>{product?.catalogItemDescription}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetail;
