import React, { useEffect, useRef } from 'react';
import { Row, Container, Spinner } from 'react-bootstrap';
import useOrderApi from '../../managers/OrderManager';
import { useNavigate } from 'react-router-dom';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import usePaymentApi from '../../managers/PaymentManager';

const Success: React.FC = () => {
  const { createOrder, submitOrder } = useOrderApi();
  const navigate = useNavigate();
  const { clearCart, cartItems } = useShoppingCart();
  const { getPayment, clearPayment } = usePaymentApi();

  const isSubmitting = useRef(false); // Ref to track submission state

  useEffect(() => {
    const submitOrderAfterPayment = async () => {
      if (isSubmitting.current) return; // Prevent multiple submissions
      isSubmitting.current = true; // Mark as submitting to prevent further submissions

      try {
        // Validate payment session
        const payment = await getPayment();
        if (!payment.paymentSessionId) {
          throw new Error('Payment Session Id not found');
        }

        let initialOrder = await createOrder(cartItems, payment);

        // Submit the order using the payment session
        const submittedOrder = await submitOrder(initialOrder);
        if (!submittedOrder) {
          throw new Error('Error submitting order');
        }

        console.log('Order successfully submitted:', submittedOrder);

        // Navigate to the order confirmation page
        navigate(`/order/${submittedOrder.orderId}`, { state: { orderId: submittedOrder.orderId } });
      } catch (error: any) {
        console.error('Error submitting order:', error?.message);
        navigate('/order/error', { state: { error: error?.message } })
      } finally {
        // clear the cart since we know the payment went through at this point
        clearCart();
        clearPayment();
        isSubmitting.current = false;
      }
    };

    submitOrderAfterPayment();
  }, []);

  return (
    <Container>
      <Row>
        <h1>Payment Successful</h1>
        <p>Your payment has been processed successfully. Submitting order to fulfillment...</p>
        <Spinner />
      </Row>
    </Container>
  );
};

export default Success;
