import React, { useEffect, useState } from "react";
import useSiteApi from "../managers/SiteManager";

const Sitemap: React.FC = () => {
  const { fetchSitemap } = useSiteApi();
  const [sitemap, setSitemap] = useState<string>("");

  useEffect(() => {
    const loadSitemap = async () => {
      try {
        const response = await fetchSitemap();

        if (typeof response === "string") {
          // If it's a string, set it directly
          setSitemap(response);
        } else if (response instanceof ReadableStream) {
          // If it's a ReadableStream, process it
          const reader = response.getReader();
          let text = "";

          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            text += new TextDecoder().decode(value);
          }

          setSitemap(text);
        } else {
          throw new Error("Unexpected response type");
        }
      } catch (error) {
        console.error("Error loading sitemap:", error);
        setSitemap("Failed to load sitemap.");
      }
    };

    loadSitemap();
  }, []);

  return (
    <div>
      <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{sitemap}</pre>
    </div>
  );
};

export default Sitemap;
