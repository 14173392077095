import React, { useState } from "react";
import { Button, Form, Input, Container, Row, Col, Modal, ModalBody, FormGroup } from "reactstrap";
import useSiteApi from "../../managers/SiteManager";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import contactUsImage from "../../assets/contactus.webp";
import { useNavigate } from "react-router-dom";

const ContactUs: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const { getCaptchaKey, postContactUs } = useSiteApi();
  const captchaKey = getCaptchaKey() || "10000000-ffff-ffff-ffff-000000000001";

  // Toggle modal and clear form when closing
  const toggleModal = () => {
    if (isModalOpen) {
      clearForm();
      navigate("/");
    }
    setModalOpen(!isModalOpen);
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setMobile("");
    setOrderNumber("");
    setMessage("");
    setCaptchaToken(null);
    setErrorMessage("");
  };

  const handleCaptchaVerify = (token: string) => {
    console.log("Captcha verified with token:", token);
    setCaptchaToken(token);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!name || !message || (!email && !mobile)) {
      setErrorMessage("Please fill out all required fields. Email or mobile number is required.");
      return;
    }

    if (!captchaToken) {
      setErrorMessage("Please complete the CAPTCHA.");
      return;
    }

    setErrorMessage("");
    handleSubmitForm(captchaToken);
  };

  const handleSubmitForm = async (token: string) => {
    if (!token) {
      setErrorMessage("CAPTCHA verification failed. Please try again.");
      return;
    }

    setLoadingState(true);
    try {
      // Use the values from the form when calling postContactUs
      const response = await postContactUs({ name, email, mobile, orderNumber, message });
      console.log("Response:", response);
      setLoadingState(false);
      toggleModal(); // Open the modal to show success message
    } catch (error) {
      console.error("Error:", error);
      setLoadingState(false);
      setErrorMessage("Something went wrong. Please try again.");
    }
  };

  return (
    <Container className="py-5">
      <h1 className="text-center mb-4">Contact Us</h1>
      <Row>
        <Col md="6" className="text-center">
          <img
            src={contactUsImage}
            alt="Contact Us"
            className="img-fluid rounded"
            style={{ maxWidth: "100%", objectFit: "contain" }}
          />
        </Col>
        <Col md="6">
          <p>Please fill out the form below, and we'll get back to you as soon as possible.</p>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <label htmlFor="name">Your Name</label>
              <Input
                id="name"
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="email">Your Email</label>
              <Input
                id="email"
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="mobile">Your Mobile Number (Optional)</label>
              <Input
                id="mobile"
                type="text"
                placeholder="Your Mobile Number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="orderNumber">Order Number (Optional)</label>
              <Input
                id="orderNumber"
                type="text"
                placeholder="Order Number"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="message">Your Message</label>
              <Input
                id="message"
                type="textarea"
                placeholder="Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={5}
                required
              />
            </FormGroup>

            {/* Visible captcha - user must check the box */}
            <HCaptcha
              {...({
                sitekey: captchaKey,
                size: "normal",
                onVerify: handleCaptchaVerify,
              } as any)}
            />

            {errorMessage && <p className="text-danger">{errorMessage}</p>}

            <Button className="w-100 mb-3" type="submit" disabled={loadingState}>
              {loadingState ? "Sending..." : "Submit"}
            </Button>
          </Form>

          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalBody className="text-center">
              <h2>Thank You!</h2>
              <p>Your message has been sent. We'll get back to you soon.</p>
              <Button color="secondary" onClick={toggleModal}>Close</Button>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
