import React from 'react';
import { Container } from 'react-bootstrap';

const Privacy: React.FC = () => {

  return (
    <Container>
      <div>
        <h1>Privacy Policy for Wallscapes.AI</h1>
        <p className="text-muted text-center">Effective Date: 2025-03-05</p>

        <h2>Overview</h2>
        <p>
          This Privacy Policy describes how GSD Solutions LLC collects, uses, and protects your information when you use Wallscapes.AI.
        </p>

        <h2>1. Information We Collect</h2>
        <ul>
          <li><strong>Personal Information:</strong> Name, email address, billing and shipping addresses, payment details.</li>
          <li><strong>Device Information:</strong> IP address, browser type, cookies, and usage data.</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <ul>
          <li>To process orders and manage your account.</li>
          <li>To improve the user experience and enhance our services.</li>
          <li>For marketing purposes, only with your consent.</li>
        </ul>

        <h2>3. Sharing Your Information</h2>
        <ul>
          <li>Information may be shared with third-party vendors for order fulfillment and payment processing.</li>
          <li>We use analytics tools like Google Analytics to understand user behavior on our site.</li>
        </ul>

        <h2>4. Cookies and Tracking</h2>
        <p>
          Wallscapes.AI uses cookies to enhance functionality. You can manage cookie settings via your browser.
        </p>

        <h2>5. Your Rights</h2>
        <ul>
          <li>Access, update, or delete your personal information by contacting wallscapesai@gmail.com.</li>
          <li>Opt-out of marketing communications at any time.</li>
        </ul>

        <h2>6. Data Security</h2>
        <ul>
          <li>Your information is stored securely and only accessible by authorized personnel.</li>
          <li>While we take robust measures to protect your data, no method of storage or transmission is 100% secure.</li>
        </ul>

        <h2>7. International Users</h2>
        <p>
          If you are accessing the service from outside the United States, be aware that your information may be transferred to and stored in the U.S.
        </p>

        <h2>8. Contact Information</h2>
        <ul>
          <li><strong>Email:</strong> wallscapesai@gmail.com</li>
        </ul>
      </div>
    </Container>
  );
};

export default Privacy;